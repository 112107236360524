@import "../styles/Variables/Variables.scss";


#addform{

    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 40px;
    padding-bottom:30px;

    #addheading{
        font-size: 18px;
        font-family: $sffont-medium;
        margin-bottom: 20px;
    }

    .inputbox{
        padding: 10px;
        border: 1px solid #ced4da;
        border-radius: 5px;
        width: 100%;
        color: $black-color;
        font-size: 14px;
    }
    .inputbox1{
        padding: 10px;
        border: none;
        border-radius: 5px;
        width: 100%;
        background-color: $darkthemecolor;
        color: $lightthemetext;
        font-family: $sffont-regular;
    }
    .formlabel{
        font-family: $sffont-regular;
        font-size: 14px;
    }
    .formbtn{
        background-color: $primary-btncolor;
        color: white;
        border: none;
        width: 100px;
        font-size: 16px;
        padding: 11px;
        border-radius: 5px;
        margin: 0px 10px 10px 0px;
        font-family: $sffont-medium;

        &:hover{
            background-color: $secondary-btncolor;
        }
    }
    .resetbtn{
        margin-right: 10px;
        background-color: #b4b8c063; 
        color: #212529;
        width: 100px;
        font-size: 16px;
        padding: 11px;
        border-radius: 5px;
        border: none;
        font-family: $sffont-medium;
        margin-bottom: 10px;

            
        &:hover{
            background-color: $secondary-btncolor;
            color: #fff; 
        }
    }


    ::placeholder{
        font-size: 14px;
    }
    .arrowback{
        float: right;
        background-color: #dde2eb;
        padding: 10px;
        color: #39979d;
        cursor: pointer;
        border-radius: 3px;

          &:hover{
            background-color: #39979d;
            color: #fff;
          }
      }
  }
  


  @media only screen and (max-width: 343px) {
    .formbtn{
        float: left !important;
    }
  }



  @media only screen and (max-width: 600px) {
    #dashboardcont2{
        width: $dashwidth;
        left: $dashleft;
        position: relative;
        padding: $dashboardpadding;
    }
}
