@import "../styles/Variables/Variables.scss";

.indexdiv{

    #empheading{
        font-size: 18px;
        font-family: $sffont-medium;
        padding-bottom: 50px;
    }
    .exportsection{
        width: 100%;
        margin-top: 25px;

        #dropdown{
            float:right;
        }
        #dropdown button{
            background-color:$primary-btncolor;
            border-radius:5px;
            border:none;
            box-shadow:none;
            font-size:14px;
            font-family: $sffont-regular;
            cursor: pointer;
        }
        a{
            font-size:14px;
            font-family: $sffont-regular;
            cursor: pointer;
        }
    }
}
.inputbox{
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 100%;
    color: $black-color;
    font-size: 14px;
}
.inputbox1{
    padding: 10px;
    border: none;
    border-radius: 5px;
    width: 100%;
    background-color: $darkthemecolor;
    color: $lightthemetext;
    font-family: $sffont-regular;
}
#actionbtn{
    background: none;  
    border: none;
    margin-right: 10px;
}
.formbtn{
    background-color: $primary-btncolor;
    color: white;
    border: none;
    min-width: 100px;
    font-size: 16px;
    padding: 10px 13px;
    border-radius: 5px;
    margin: 0px 10px 10px 0px;
    font-family: $sffont-medium;

    &:hover{
        background-color: $secondary-btncolor;
    }
}
@media only screen and (max-width: 768px) {
    .exportsection{
        margin-top: 10px !important;

        #dropdown{
            float:left !important;
        }
    }
}

@media only screen and (max-width: 600px) {
    #dashboardcont2{
        width: $dashwidth;
        left: $dashleft;
        position: relative;
        padding: $dashboardpadding;
    }
}

